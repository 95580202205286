export var InsuranceType;
(function (InsuranceType) {
    InsuranceType["Auto"] = "auto";
    InsuranceType["Home"] = "home";
})(InsuranceType || (InsuranceType = {}));
export var InsuranceProducts;
(function (InsuranceProducts) {
    InsuranceProducts["Auto"] = "auto";
    InsuranceProducts["Home"] = "home";
    InsuranceProducts["AutoHome"] = "auto_home";
    InsuranceProducts["AutoRenters"] = "auto_renters";
    InsuranceProducts["Renters"] = "renters";
    InsuranceProducts["Jewelry"] = "jewelry";
    InsuranceProducts["Health"] = "health";
    InsuranceProducts["Pet"] = "pet";
    InsuranceProducts["Life"] = "life";
    InsuranceProducts["Motorcycle"] = "motorcycle";
    InsuranceProducts["Landlord"] = "landlord";
    InsuranceProducts["Business"] = "business";
    InsuranceProducts["Flood"] = "flood";
    InsuranceProducts["Earthquake"] = "earthquake";
    InsuranceProducts["Cyber"] = "cyber";
    InsuranceProducts["CellPhone"] = "cell_phone";
})(InsuranceProducts || (InsuranceProducts = {}));
export var UserIntent;
(function (UserIntent) {
    UserIntent["BETTER_RATE"] = "better_rate";
    UserIntent["BETTER_COVERAGE"] = "better_coverage";
    UserIntent["NEW_AUTO_POLICY"] = "new_auto_policy";
    UserIntent["BROWSE"] = "browse";
})(UserIntent || (UserIntent = {}));
export var POST_MESSAGE_TYPES;
(function (POST_MESSAGE_TYPES) {
    POST_MESSAGE_TYPES["CLICKED_BACK_BUTTON"] = "clicked_back_button";
    POST_MESSAGE_TYPES["NAVIGATE_BACK"] = "navigate_back";
    POST_MESSAGE_TYPES["RESET_STATE"] = "reset_state";
    POST_MESSAGE_TYPES["CHANGE_PHASE"] = "change_phase";
    POST_MESSAGE_TYPES["OPEN"] = "open";
    POST_MESSAGE_TYPES["EXIT"] = "exit";
    POST_MESSAGE_TYPES["FORCED_EXIT"] = "forced_exit";
    POST_MESSAGE_TYPES["CLOSE"] = "close";
    POST_MESSAGE_TYPES["SAVVY_GCLID_LOADED"] = "SAVVY_GCLID_LOADED";
})(POST_MESSAGE_TYPES || (POST_MESSAGE_TYPES = {}));
export var EXPERIENCE;
(function (EXPERIENCE) {
    EXPERIENCE["CONNECT"] = "connect";
    EXPERIENCE["REMARKETING"] = "remarketing";
    EXPERIENCE["UNLINKED"] = "unlinked";
    EXPERIENCE["BUNDLE_REMARKETING"] = "bundle_remarketing";
    EXPERIENCE["RECONNECT"] = "reconnect";
    EXPERIENCE["PREFILL"] = "prefill";
    EXPERIENCE["SAVVY"] = "savvy";
    EXPERIENCE["RETURN_APPLICANT"] = "return_applicant";
})(EXPERIENCE || (EXPERIENCE = {}));
export var CONNECT_STATUSES;
(function (CONNECT_STATUSES) {
    CONNECT_STATUSES["ENTERED"] = "ENTERED";
    CONNECT_STATUSES["EXITED"] = "EXITED";
})(CONNECT_STATUSES || (CONNECT_STATUSES = {}));
export var TrellisEnvs;
(function (TrellisEnvs) {
    TrellisEnvs["Dev"] = "dev";
    TrellisEnvs["QA"] = "qa";
    TrellisEnvs["Prod"] = "prod";
})(TrellisEnvs || (TrellisEnvs = {}));
export var OnboardExperienceVersions;
(function (OnboardExperienceVersions) {
    OnboardExperienceVersions["v1"] = "v1";
    OnboardExperienceVersions["v2"] = "v2";
})(OnboardExperienceVersions || (OnboardExperienceVersions = {}));
